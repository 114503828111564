import React from 'react'
import { Layout, Title } from '../components/index';
import { Link } from 'gatsby';
import './404.scss'
import {Helmet} from 'react-helmet';
import Favicon from '../img/favicon3.png';

export default function () {
    return (
        <Layout>
            <Helmet>
                <title>Oops! 404 - Art Organization Creative House</title>
                <meta name='title' content='Oops! 404 - Art Organization Creative House' />
                <meta name='description' content='Creative House is art organization founded in 2018, which deals with the publication and distribution of quality literary works, the production of original animated films and the organization and implementation of creative workshops and events related to literature and animated film. Our mission is to connect reality and imagination into original art expressions and to expand the space of creative freedom. The founders and main implementers of the program of the Art Organization CREATIVE HOUSE are the multi-award winning writer and screenwriter Nena Lončar and the artist Marijan Lončar.' />
                <link rel='shortcut icon' type='image/x-icon' href={Favicon} />
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            </Helmet>
            <div className='page-404'>
                <Title title='Oops!' subtitle='Subpage does not exist.' />
                <div className="404-button-wrapper"><Link to='/'><button className='button-home-404'>Home page</button></Link></div>
            </div>
        </Layout>
    )
}
